import type { CartStoreState, IMediaStateManager, IPricesStateManager } from '../../state-managers';
import type {
  DerivedModifierGroupState,
  DerivedModifierGroupsState,
  DerivedModifierState,
  ItemServiceInternalState,
  ItemServiceStoreState,
  ModGroupService,
  ModGroupServiceContext,
  ModifierGroupState,
  ModifierGroupsState,
  ModifierService,
  ModifierServiceDerivedState,
  ModifierState,
} from './types';
import type {
  KioskAvailableTimes,
  KioskCategory,
  KioskCategoryDisplayableItem,
  KioskCombo,
  KioskMenuItem,
  KioskModifier,
  KioskModifierGroup,
} from '../../models';
import {
  MenuItemDisplayFlow,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType,
  NegativeModifierBehavior,
} from '@grubbrr/nextgen-kiosk-client';

import type { AppearanceConfig } from '../../context/apperance_context';
import { DefaultModifierPricingBehavior } from '@grubbrr/nextgen-kiosk-client';
import type { EightySixedItems } from '../eighty_six/eighty_six_service';
import type { ModifierCode } from '@grubbrr/nextgen-kiosk-client';
import { ModifierCodeBehavior } from '@grubbrr/nextgen-kiosk-client';
import { get } from 'svelte/store';
import { isValidOneOf } from '../../data-management/utils';

const MIN_QUANTITY = 1;

export const getNegativeModifierCode = (selectedModifierCode: ModifierCode[]) => {
  const negModifier = selectedModifierCode?.filter(
    (x) => x.modifierCodeBehavior === ModifierCodeBehavior.Negative
  );
  return negModifier;
};

export function isContainsNegativeModifierCode(selectedModifierCode: ModifierCode[]): boolean {
  const negModifier = getNegativeModifierCode(selectedModifierCode);
  if (negModifier?.length > 0) {
    return true;
  }
  return false;
}

export const firstModifierGroupId = (item: KioskMenuItem) => {
  return item.modifierGroups.length === 0 ? undefined : item.modifierGroups[0].modifierGroupId;
};

export const lastModifierGroupId = (item: KioskMenuItem) => {
  return item.modifierGroups.length === 0
    ? ''
    : item.modifierGroups[item.modifierGroups.length - 1].modifierGroupId;
};

const isSelectedChildModifierGroupValid = (
  modifierState: ModifierState | ItemServiceInternalState,
  childModifierGroupDerivedState: DerivedModifierGroupsState
) => {
  if (!modifierState.selected_group_id) {
    return true;
  }

  const derivedModifierGroupState = childModifierGroupDerivedState.get(
    modifierState.selected_group_id
  );
  return derivedModifierGroupState?.state.display.is_valid ?? true;
};

const getDerivedStateForModGroup = (
  comboId: string,
  rootItemId: string,
  mediaStateManager: IMediaStateManager,
  priceStateManager: IPricesStateManager,
  eightySixItems: EightySixedItems,
  parentState: {
    selectedGroupId?: string;
  },
  modGroupState: ModifierGroupState,
  service: ModGroupService,
  group: KioskModifierGroup,
  defaultModifierPricingBehavior: DefaultModifierPricingBehavior,
  isNestedMod = false
): DerivedModifierGroupState => {
  const modServicesById = new Map(service.modifierServices.map((x) => [x.modifier.modifierId, x]));

  // eslint-disable-next-line camelcase
  const getModifierPriceWithExtraModifierCode = (mod, mod_state) => {
    return getPriceForMod(mod, mod_state) + getPriceForExtraModifierCode(mod, mod_state);
  };

  const calculateFreeModifierCountBasedOnPizzaCoverage = (
    mod,
    // eslint-disable-next-line camelcase
    mod_state,
    newConverageIndexes,
    // eslint-disable-next-line camelcase
    free_quantity,
    // eslint-disable-next-line camelcase
    available_free_mods
  ) => {
    // eslint-disable-next-line camelcase
    if (isModifierPizzaTopping(group) && mod_state) {
      const converageIndex = getChildSelectedModifierPizzaCoverage(mod, mod_state);
      // If coverage is left then check if qty of right is greater than left but less than Free modifier count
      // else If coverage is right then check if qty of left is greater than right but less than Free modifier count
      // then update the free quantity and available free mods
      if (converageIndex === 0) {
        if (
          newConverageIndexes[2] > newConverageIndexes[0] &&
          group.freeModifierCount >= newConverageIndexes[2]
        ) {
          // eslint-disable-next-line camelcase
          free_quantity = 1;
          // eslint-disable-next-line camelcase
          available_free_mods += 1;
        }
      } else if (converageIndex === 2) {
        if (
          newConverageIndexes[0] > newConverageIndexes[2] &&
          group.freeModifierCount >= newConverageIndexes[0]
        ) {
          // eslint-disable-next-line camelcase
          free_quantity = 1;
          // eslint-disable-next-line camelcase
          available_free_mods += 1;
        }
      }
      newConverageIndexes[converageIndex] += 1;
    }
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
    return [free_quantity, available_free_mods, newConverageIndexes];
  };

  // eslint-disable-next-line camelcase
  const getChildSelectedModifierPizzaCoverage = (modifier, mod_state: ModifierState) => {
    const modService = modServicesById.get(modifier.modifierId);
    for (const childModifierGroupService of modService?.modifierGroupServices?.values() ?? []) {
      // eslint-disable-next-line camelcase
      const childState = mod_state.mod_groups.get(childModifierGroupService.group.modifierGroupId);
      if (childState && isModifierPizzaCoverage(childModifierGroupService.group)) {
        const coverageIndex = Array.from(childState.modifiers.values()).findIndex(
          (modifier) => modifier.quantity > 0
        );
        if (coverageIndex !== -1) {
          return coverageIndex;
        }
      }
    }
    return 1;
  };

  // eslint-disable-next-line camelcase
  const processPizzaCoveragePrice = (modifier, mod_state: ModifierState, price: number) => {
    const converageIndex = getChildSelectedModifierPizzaCoverage(modifier, mod_state);
    // If coverage is 1st or 3rd then divide it by 2
    if (converageIndex !== 1) price /= 2;
    return price;
  };
  const getPriceForMod = (
    modifier: KioskModifier,
    // eslint-disable-next-line camelcase
    mod_state: ModifierState | undefined = undefined
  ) => {
    let price = priceStateManager.getPriceForModifierInItem(rootItemId, modifier.modifierId) ?? 0;
    if (comboId) {
      price =
        priceStateManager.getPriceForModifierInItemInCombo(
          comboId,
          rootItemId,
          modifier.modifierId
        ) ?? 0;
    }
    // eslint-disable-next-line camelcase
    if (isModifierPizzaTopping(group) && mod_state && price) {
      return processPizzaCoveragePrice(modifier, mod_state, price);
    }
    return price;
  };
  // eslint-disable-next-line camelcase
  const getPriceForExtraModifierCode = (mod: KioskModifier, mod_state: ModifierState) => {
    // eslint-disable-next-line camelcase
    if (!mod_state.selectedModifierCode || mod.isDefault) return 0;
    // eslint-disable-next-line camelcase
    if (mod_state.selectedModifierCode.modifierCodeBehavior == ModifierCodeBehavior.Extra) {
      return getPriceForMod(mod, mod_state);
    }
    return 0;
  };

  const {
    // eslint-disable-next-line camelcase
    starting_price,
    price,
    // eslint-disable-next-line camelcase
    is_valid,
    quantity,
    // eslint-disable-next-line camelcase
    available_free_mods,
    // eslint-disable-next-line camelcase
    mod_states,
    negativeModifierQuantity,
    accumulatedModifierPrice,
    orderReviewPrice,
  } = [...modGroupState.modifiers.entries()]
    .map(([modifierId, modifierState]) => {
      {
        return {
          // TODO: Use dictionary
          mod: group.modifiers.find((x) => x.modifierId === modifierId)!,
          // eslint-disable-next-line camelcase
          mod_state: modifierState,
          // eslint-disable-next-line camelcase
          neg_mod_behaviour: group.negativeModifierBehavior,
        };
      }
    })
    .sort(
      (a, b) =>
        /**
         * Sort by price or sort by customer selection
         */
        getModifierPriceWithExtraModifierCode(a.mod, a.mod_state) -
          getModifierPriceWithExtraModifierCode(b.mod, b.mod_state) ||
        (a.mod_state.customerSelectionIndex ?? 0) - (b.mod_state.customerSelectionIndex ?? 0)
    )
    .reduce(
      (
        {
          // eslint-disable-next-line camelcase
          starting_price,
          price,
          // eslint-disable-next-line camelcase
          is_valid,
          quantity,
          counted,
          // eslint-disable-next-line camelcase
          available_free_mods,
          // eslint-disable-next-line camelcase
          mod_states,
          negativeModifierQuantity,
          accumulatedModifierPrice,
          orderReviewPrice,
          converageIndexes,
        },
        _mod
      ) => {
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        const { mod, mod_state, neg_mod_behaviour } = _mod;

        const isModifier86 = mod?.menuItemId ? eightySixItems.has(mod.menuItemId) : false;

        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        const modifier_selected_quantity = !isModifier86 ? mod_state.quantity : 0;

        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        const new_quantity = quantity + modifier_selected_quantity;

        const modService = modServicesById.get(mod.modifierId);
        const modifierPrice =
          // eslint-disable-next-line camelcase
          getModifierPriceWithExtraModifierCode(mod, mod_state) * modifier_selected_quantity;
        // eslint-disable-next-line camelcase
        let new_price_with_modifiers = modifierPrice;
        // eslint-disable-next-line camelcase
        let new_starting_price = modifierPrice;
        // eslint-disable-next-line camelcase
        let new_is_valid = true;
        // eslint-disable-next-line camelcase
        let first_invalid_group_id: string | undefined = undefined;
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        let priced_quantity = modifier_selected_quantity;

        // let itemModifiers: ItemModifier[] = [];
        const derivedModifierGroupState: DerivedModifierGroupsState = new Map();
        let accumulatedNestedModifierPrice = 0;
        for (const childModifierGroupService of modService?.modifierGroupServices?.values() ?? []) {
          // eslint-disable-next-line camelcase
          const childState = mod_state.mod_groups.get(
            childModifierGroupService.group.modifierGroupId
          );
          const derivedState = getDerivedStateForModGroup(
            comboId,
            rootItemId,
            mediaStateManager,
            priceStateManager,
            eightySixItems,
            {
              // eslint-disable-next-line camelcase
              selectedGroupId: mod_state.selected_group_id,
            },
            childState!,
            childModifierGroupService,
            childModifierGroupService.group,
            defaultModifierPricingBehavior,
            true
          );

          // eslint-disable-next-line camelcase
          new_price_with_modifiers += derivedState.state.display.price;
          // eslint-disable-next-line camelcase
          new_starting_price += derivedState.state.display.starting_price;
          accumulatedNestedModifierPrice += derivedState.state.display.pricesWithNestedModifiers;

          // only check if a child modifier is valid if its parent is selected
          // eslint-disable-next-line camelcase
          if (modifier_selected_quantity > 0) {
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            new_is_valid = new_is_valid && derivedState.state.display.is_valid;
          }
          // eslint-disable-next-line camelcase
          if (first_invalid_group_id === undefined && !derivedState.state.display.is_valid) {
            // eslint-disable-next-line camelcase
            first_invalid_group_id = childModifierGroupService.group.modifierGroupId;
          }

          derivedModifierGroupState.set(
            childModifierGroupService.group.modifierGroupId,
            derivedState
          );
        }

        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        new_price_with_modifiers *= modifier_selected_quantity;

        //free mods..
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        let new_selected_free_mods = available_free_mods;
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        let price_delta = new_price_with_modifiers;
        // eslint-disable-next-line camelcase
        let new_free_quantity: number;
        // eslint-disable-next-line camelcase
        let free_quantity = Math.max(0, Math.min(modifier_selected_quantity, available_free_mods));
        let newConverageIndexes = [...converageIndexes];
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        if (group.freeModifierCount > 0 && modifier_selected_quantity >= 1 && price_delta !== 0) {
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          [free_quantity, available_free_mods, newConverageIndexes] =
            calculateFreeModifierCountBasedOnPizzaCoverage(
              mod,
              mod_state,
              newConverageIndexes,
              free_quantity,
              available_free_mods
            );

          // eslint-disable-next-line camelcase
          const price_per_unit = modifierPrice;
          // eslint-disable-next-line camelcase
          let free_mod_price: number;
          // eslint-disable-next-line camelcase
          if (free_quantity === 0) {
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            free_mod_price = price_per_unit * modifier_selected_quantity;
          } else {
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            // eslint-disable-next-line camelcase
            free_mod_price = price_per_unit * (modifier_selected_quantity - free_quantity);
          }

          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          new_free_quantity = free_quantity;

          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          new_selected_free_mods = available_free_mods - free_quantity;
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          priced_quantity -= new_free_quantity;
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          price_delta = free_mod_price;
        } else {
          // eslint-disable-next-line camelcase
          new_free_quantity = 0;
        }
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        is_valid = is_valid && new_is_valid;

        if (counted < group.selectMin && mod) {
          const remaining = group.selectMin - counted;
          const count = Math.floor(
            (remaining >= mod.maxQuantity ? mod.maxQuantity : remaining) / mod.incrementStep
          );
          // eslint-disable-next-line camelcase
          starting_price += getModifierPriceWithExtraModifierCode(mod, mod_state) * count;
          counted += count * mod.incrementStep;
        }

        const maxQuantity = mod!.maxQuantity;

        const isDefaultModifier = mod.defaultQuantity > 0;
        const isPizzaTopping = isModifierPizzaTopping(group);
        let negativeModifierCount =
          // eslint-disable-next-line camelcase
          (neg_mod_behaviour === NegativeModifierBehavior.ShowDefaultModifiersAsPreSelected ||
            // eslint-disable-next-line camelcase
            neg_mod_behaviour === NegativeModifierBehavior.ShowNegativeModifiersAsUnselected ||
            isPizzaTopping) &&
          !isModifier86 &&
          // eslint-disable-next-line camelcase
          modifier_selected_quantity === 0 &&
          isDefaultModifier
            ? 1
            : 0;

        // const hasNegativeModifierCode = hasNegativeModifier(modService)[0];
        let hasNegativeModifierCode = false;

        // eslint-disable-next-line camelcase
        if (mod_state.selectedModifierCode) {
          hasNegativeModifierCode = isContainsNegativeModifierCode([
            // eslint-disable-next-line camelcase
            mod_state.selectedModifierCode,
          ]);
        }

        if (negativeModifierCount > 0) {
          hasNegativeModifierCode = isContainsNegativeModifierCode(mod.modifierCodes);
        }

        if (!hasNegativeModifierCode) {
          negativeModifierCount = 0;
        }

        const newNegativeModCount = negativeModifierQuantity + negativeModifierCount;

        const shouldModifierPriceBeZero =
          isDefaultModifier &&
          defaultModifierPricingBehavior ===
            DefaultModifierPricingBehavior.ExcludeDefaultModifierPrice &&
          group.freeModifierCount === 0 &&
          // eslint-disable-next-line camelcase
          (!mod_state.selectedModifierCode ||
            // eslint-disable-next-line camelcase
            (mod_state.selectedModifierCode &&
              // eslint-disable-next-line camelcase
              mod_state.selectedModifierCode?.modifierCodeBehavior !== ModifierCodeBehavior.Extra));
        if (shouldModifierPriceBeZero) {
          // eslint-disable-next-line camelcase
          price_delta = 0;
        }

        const modPrice = !shouldModifierPriceBeZero
          ? getModifierPriceWithExtraModifierCode(mod, mod_state)
          : 0;
        const orderReviewModPriceSubtotal =
          defaultModifierPricingBehavior ===
            DefaultModifierPricingBehavior.ExcludeDefaultModifierPrice && isDefaultModifier
            ? 0
            : getModifierPriceWithExtraModifierCode(mod, mod_state);
        // eslint-disable-next-line camelcase
        const orderReviewModPrice = orderReviewModPriceSubtotal * priced_quantity;
        const orderReviewModPriceWithNested =
          // eslint-disable-next-line camelcase
          orderReviewModPrice + accumulatedNestedModifierPrice * modifier_selected_quantity;
        const totalPriceForCurrentModifierAndNested =
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          modPrice * priced_quantity + accumulatedNestedModifierPrice * modifier_selected_quantity;

        const isThisModFreeOrPartiallyFree =
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          free_quantity > 0 && free_quantity === modifier_selected_quantity;
        const derivedState: DerivedModifierState = {
          modifier: mod,
          state: {
            media: mediaStateManager.getItemMedia(mod.menuItem.menuItemId),
            modifierId: mod.modifierId,
            context: {
              // eslint-disable-next-line camelcase
              last_mod_group_id: lastModifierGroupId(mod.menuItem),
            },
            startingPrice: getModifierPriceWithExtraModifierCode(mod, mod_state),
            display: {
              // eslint-disable-next-line camelcase
              ...mod_state,
              negativeModifierQuantity: negativeModifierCount,
              // eslint-disable-next-line camelcase
              default_quantity: isModifier86 ? 0 : mod.defaultQuantity,
              // eslint-disable-next-line camelcase
              // eslint-disable-next-line camelcase
              free_quantity: new_free_quantity,
              // eslint-disable-next-line camelcase
              // eslint-disable-next-line camelcase
              is_valid: !isModifier86 && new_is_valid,
              // eslint-disable-next-line camelcase
              // eslint-disable-next-line camelcase
              starting_price: shouldModifierPriceBeZero ? 0 : new_starting_price,
              price:
                getModifierPriceWithExtraModifierCode(mod, mod_state) *
                // eslint-disable-next-line camelcase
                priced_quantity,
              cardDisplayPrice: isThisModFreeOrPartiallyFree ? 0 : modPrice,
              orderReviewPrice: orderReviewModPrice,
              orderReviewPriceWithNested: orderReviewModPriceWithNested,
              pricesWithNestedModifiers: totalPriceForCurrentModifierAndNested,
              // eslint-disable-next-line camelcase
              // eslint-disable-next-line camelcase
              can_decrease: modifier_selected_quantity > MIN_QUANTITY,
              // eslint-disable-next-line camelcase
              // eslint-disable-next-line camelcase
              can_increase: maxQuantity === 0 || modifier_selected_quantity < maxQuantity,
              // eslint-disable-next-line camelcase
              out_of_stock: isModifier86,
              // eslint-disable-next-line camelcase
              mod_groups: new Map(
                [...(modService?.modifierGroupServices?.values() ?? [])].map((x) => {
                  return [x.group.modifierGroupId, x];
                })
              ),
              hasNestedModifiers: (modService?.modifierGroupServices.size ?? 0) > 0,
              modifierCode: negativeModifierCount > 0 ? 'No ' : '',
              isUnselectedDefaultModifier: negativeModifierCount > 0,
              // eslint-disable-next-line camelcase
              isSelectedDefaultModifier: isDefaultModifier && modifier_selected_quantity > 0,
              // eslint-disable-next-line camelcase
              isSelectedNonDefaultModifier: !isDefaultModifier && modifier_selected_quantity > 0,
              isSelectedChildModifierGroupValid: isSelectedChildModifierGroupValid(
                mod_state,
                derivedModifierGroupState
              ),
              isInvisible: mod.isInvisible,
            },
          },
          modifierGroups: derivedModifierGroupState,
        };

        // eslint-disable-next-line camelcase
        mod_states.push(derivedState);

        return {
          counted,
          // eslint-disable-next-line camelcase
          starting_price,
          // order_details:
          //   new_quantity === quantity
          //     ? negativeModifierCount > 0
          //       ? order_details.concat(derivedState.state.context.order_details)
          //       : order_details
          //     : order_details.concat(derivedState.state.context.order_details),
          // eslint-disable-next-line camelcase
          price: price + price_delta,
          // eslint-disable-next-line camelcase
          is_valid,
          // eslint-disable-next-line camelcase
          quantity: new_quantity,
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          available_free_mods: new_selected_free_mods,
          // eslint-disable-next-line camelcase
          mod_states,
          negativeModifierQuantity: newNegativeModCount,
          accumulatedModifierPrice:
            accumulatedModifierPrice + totalPriceForCurrentModifierAndNested,
          orderReviewPrice: orderReviewPrice + orderReviewModPriceWithNested,
          converageIndexes: newConverageIndexes,
        };
      },
      {
        counted: 0,
        // order_details: [] as ItemModifier[],
        quantity: 0,
        price: 0,
        // eslint-disable-next-line camelcase
        starting_price: 0,
        // eslint-disable-next-line camelcase
        is_valid: true,
        // eslint-disable-next-line camelcase
        available_free_mods: group.freeModifierCount,
        // eslint-disable-next-line camelcase
        mod_states: [] as DerivedModifierState[],
        negativeModifierQuantity: 0,
        accumulatedModifierPrice: 0,
        orderReviewPrice: 0,
        converageIndexes: [0, 0, 0],
      }
    );
  // If there is available free mods then make all the modifier's display price as 0
  // eslint-disable-next-line camelcase
  if (available_free_mods > 0) {
    // eslint-disable-next-line camelcase
    mod_states.forEach((mod_state) => (mod_state.state.display.cardDisplayPrice = 0));
  }
  const remainingQuantity = !group.selectMax ? null : group.selectMax - quantity;
  return {
    modifierGroup: group,
    modifiersState: new Map<string, DerivedModifierState>(
      // eslint-disable-next-line camelcase
      mod_states.map((m) => {
        return [m.state.modifierId, m];
      })
    ),
    state: {
      modifierGroupId: group.modifierGroupId,
      // order_details: order_details,
      display: {
        modifiers: service.modifierServices,
        isSelected: parentState.selectedGroupId === group.modifierGroupId,
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        starting_price: starting_price,
        price: price,
        // eslint-disable-next-line camelcase
        is_valid:
          // eslint-disable-next-line camelcase
          is_valid &&
          quantity >= group.selectMin &&
          (!group.selectMax || quantity <= group.selectMax),
        quantity: quantity,
        // eslint-disable-next-line camelcase
        remaining_quantity: remainingQuantity,
        pricesWithNestedModifiers: accumulatedModifierPrice,
        orderReviewPrice,
        hasReachedMax: remainingQuantity === null ? false : remainingQuantity > 0,
        // eslint-disable-next-line camelcase
        selected_free_mods: Math.max(
          0,
          // eslint-disable-next-line camelcase
          Math.min(Math.abs(available_free_mods - group.freeModifierCount), group.freeModifierCount)
        ),
        isContainsNegativeMod: negativeModifierQuantity > 0,
        isNestedMod,
        isInvisible:
          group.isInvisible ||
          service.modifierServices.filter((x) => !x.modifier.isInvisible).length <= 0,
        modifierInvisibleCount:
          service.modifierServices?.filter((x) => x.modifier.isInvisible).length ?? 0,
      },
    },
  };
};

/**
 * Returns no. of CartItems from CartStore
 * @param cartStore
 * @returns
 */
export const cartItemsQuantity = (cartStore: CartStoreState): number => {
  return Array.from(cartStore.cartItems.values()).reduce(
    (sum, curr) => sum + curr.getCurrentQuantity(),
    0
  );
};

/**
 * Returns no. of CartCombos from CartStore
 * @param cartStore
 * @returns
 */
export const cartCombosQuantity = (cartStore: CartStoreState): number => {
  return Array.from(cartStore.cartCombos.values()).reduce(
    (sum, curr) => sum + curr.getQuantity(),
    0
  );
};

/**
 * Check if the Order Limit is reached
 * @param quantityLimitPerOrder
 * @param cartStore
 * @returns TRUE | FALSE
 */
export const isOrderLimitReached = (
  quantityLimitPerOrder: number,
  cartStore: CartStoreState,
  itemStateQuantity: number
): boolean => {
  if (quantityLimitPerOrder === 0) return false;

  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);
  if (itemsInCart === 0 && combosInCart === 0) {
    return quantityLimitPerOrder <= itemStateQuantity;
  } else {
    return quantityLimitPerOrder <= itemsInCart + combosInCart + itemStateQuantity;
  }
};

/**
 * Check if the Item Limit is reached
 * @param quantityLimitPerItem
 * @param cartStore
 * @param item
 * @param itemState
 * @returns TRUE | FALSE
 */
export const isItemLimitReached = (
  quantityLimitPerOrder: number,
  quantityLimitPerItem: number,
  cartStore: CartStoreState,
  item: KioskMenuItem,
  itemState: ItemServiceInternalState
): boolean => {
  if (quantityLimitPerItem === 0) return false;

  const itemQtyInCart = Array.from(cartStore.cartItems.values())
    .filter((x) => x.item.menuItemId === item.menuItemId)
    .reduce((sum, curr) => sum + curr.getCurrentQuantity(), 0);
  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);

  // eslint-disable-next-line camelcase
  const in_cart =
    Array.from(cartStore.cartItems.values())
      .map((x) => x?.item?.menuItemId === item.menuItemId && x?.uid === itemState.cartItemId)
      ?.filter((x) => x)?.length > 0;

  if (itemQtyInCart === 0) {
    if (
      quantityLimitPerOrder > 0 &&
      quantityLimitPerOrder <= itemsInCart + combosInCart + itemState.quantity
    )
      return true;

    return quantityLimitPerItem <= itemQtyInCart + itemState.quantity;
  } else {
    if (quantityLimitPerOrder > 0 && quantityLimitPerOrder <= itemsInCart + combosInCart)
      return true;

    // eslint-disable-next-line camelcase
    return in_cart
      ? quantityLimitPerItem <= itemQtyInCart
      : quantityLimitPerItem <= itemQtyInCart + itemState.quantity;
  }
};

export const isComboLimitReached = (
  quantityLimitPerOrder: number,
  quantityLimitPerItem: number,
  cartStore: CartStoreState,
  combo: KioskCombo,
  comboQuantity: number
) => {
  if (quantityLimitPerItem === 0) return false;

  const comboQtyInCart = Array.from(cartStore.cartCombos.values())
    .filter((x) => x.combo.id === combo.id)
    .reduce((sum, curr) => sum + curr.getQuantity(), 0);

  const itemsInCart = cartItemsQuantity(cartStore);
  const combosInCart = cartCombosQuantity(cartStore);
  if (comboQtyInCart === 0) {
    if (
      quantityLimitPerOrder > 0 &&
      quantityLimitPerOrder <= itemsInCart + combosInCart + comboQuantity
    )
      return true;

    return quantityLimitPerItem === comboQuantity || quantityLimitPerItem === comboQtyInCart;
  } else {
    if (quantityLimitPerOrder > 0 && quantityLimitPerOrder <= itemsInCart + combosInCart)
      return true;

    return (
      quantityLimitPerItem === comboQtyInCart + comboQuantity ||
      quantityLimitPerItem === comboQtyInCart
    );
  }
};
export const areAllModifierGroupsInVisible = (item: KioskMenuItem) => {
  if (isPizzaItem(item)) return false;
  return item.modifierGroups.every((modGroup) => {
    if (modGroup.isInvisible) {
      const selectedModifier = modGroup.modifiers.find((modifier) => modifier.isDefault);
      if (selectedModifier) {
        return areAllModifierGroupsInVisible(selectedModifier.menuItem);
      } else {
        return true;
      }
    } else {
      const areAllInvisible = modGroup.modifiers.every((modifiers) => modifiers.isInvisible);
      // If all are not invisible and there is one required visible modifier then take that visible modifier and go for nested checks
      if (!areAllInvisible && hasOneRequiredVisibleModifier(modGroup)) {
        const singleVisibleModifier = modGroup.modifiers.find((modifier) => !modifier.isInvisible);
        if (singleVisibleModifier) {
          return areAllModifierGroupsInVisible(singleVisibleModifier.menuItem);
        } else {
          return areAllInvisible;
        }
      } else {
        return areAllInvisible;
      }
    }
  });
};
export const createItemDerivedState = (
  itemState: ItemServiceInternalState,
  item: KioskMenuItem,
  eightySixItems: EightySixedItems,
  modifierGroupServices: Map<string, ModGroupService>,
  quantityLimitPerItem: number,
  quantityLimitPerOrder: number,
  cartStoreState: CartStoreState,
  pricesStateManager: IPricesStateManager,
  mediaStateManager: IMediaStateManager,
  itemSessionId: string,
  defaultModifierPricingBehavior: DefaultModifierPricingBehavior,
  comboId: string | undefined
): ItemServiceStoreState => {
  let price =
    itemState.overridedStartingPrice ?? pricesStateManager.getPriceForItem(item.menuItemId)!;
  // eslint-disable-next-line camelcase
  let starting_price =
    itemState.overridedStartingPrice ?? pricesStateManager.getPriceForItem(item.menuItemId)!;
  const orderReviewItemPrice = pricesStateManager.getPriceForItem(item.menuItemId)!;
  let orderReviewItemTotalPrice = pricesStateManager.getPriceForItem(item.menuItemId)!;
  const variablePrice = pricesStateManager.getVariablePriceForItem(item.menuItemId) ?? 0;
  const startsAtPrice = pricesStateManager.getPriceForItem(item.menuItemId)! + variablePrice;
  const showStartsAtText = !!variablePrice && !areAllModifierGroupsInVisible(item);

  // eslint-disable-next-line camelcase
  let is_valid = true;
  // eslint-disable-next-line camelcase
  let first_invalid_group_id: string | undefined = undefined;
  // let itemModifiers: ItemModifier[] = [];
  const derivedModifierGroupState: DerivedModifierGroupsState = new Map();

  for (const [modifierGroupId, state] of itemState.mod_group_states) {
    const group = item.modifierGroups.find((g) => g.modifierGroupId === modifierGroupId);
    const modGroupService = modifierGroupServices.get(modifierGroupId);
    const derivedState = getDerivedStateForModGroup(
      comboId ?? '',
      item.menuItemId,
      mediaStateManager,
      pricesStateManager,
      eightySixItems,
      {
        selectedGroupId: itemState.selected_group_id,
      },
      state,
      modGroupService!,
      group!,
      defaultModifierPricingBehavior
    );

    price += derivedState.state.display.pricesWithNestedModifiers;
    // eslint-disable-next-line camelcase
    starting_price += derivedState.state.display.starting_price;
    orderReviewItemTotalPrice += derivedState.state.display.orderReviewPrice;
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
    is_valid = is_valid && derivedState.state.display.is_valid;
    // eslint-disable-next-line camelcase
    if (first_invalid_group_id === undefined && !derivedState.state.display.is_valid) {
      // eslint-disable-next-line camelcase
      first_invalid_group_id = item.modifierGroups[0]?.modifierGroupId;
    }

    derivedModifierGroupState.set(modifierGroupId, derivedState);
  }

  const modifierGroups = [...modifierGroupServices.values()];

  const allModifierGroupsIsInVisible = !areAllModifierGroupsInVisible(item);

  const orderLimitReachedCheck = isOrderLimitReached(
    quantityLimitPerOrder,
    cartStoreState,
    itemState.quantity
  );
  const itemQuantityLimitCheck = orderLimitReachedCheck
    ? true
    : isItemLimitReached(
        quantityLimitPerOrder,
        quantityLimitPerItem,
        cartStoreState,
        item,
        itemState
      );
  const isItem86 = eightySixItems.has(item.menuItemId);
  return {
    derivedModifierGroupState,
    media: mediaStateManager.getItemMedia(item.menuItemId),
    context: {
      sourceCategory: itemState.sourceCategory,
      // eslint-disable-next-line camelcase
      price_with_modifiers: price,
      // order_details: {
      //   ...orderDetails,
      //   modifiers: itemModifiers,
      // },
      // eslint-disable-next-line camelcase
      last_mod_group_id:
        item.modifierGroups.length === 0
          ? ''
          : item.modifierGroups[item.modifierGroups.length - 1].modifierGroupId,
    },
    modifierGroups,
    selectedModifierGroupIndex: modifierGroups.findIndex(
      (x) => x.group.modifierGroupId === itemState.selected_group_id
    ),
    display: {
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      is_valid: is_valid && !isItem86,
      // eslint-disable-next-line camelcase
      can_decrease: itemState.quantity > MIN_QUANTITY,
      quantity: itemState.quantity,
      // eslint-disable-next-line camelcase
      out_of_stock: isItem86,
      // eslint-disable-next-line camelcase
      can_increase: true,
      unitPrice: price,
      price: price * itemState.quantity,
      orderReviewTotalPrice: orderReviewItemTotalPrice * itemState.quantity,
      orderReviewItemPrice,
      startsAtPrice,
      showStartsAtText,
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      first_invalid_group_id: first_invalid_group_id,
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      starting_price: starting_price,
      // eslint-disable-next-line camelcase
      selected_group_id: itemState.selected_group_id,
      // eslint-disable-next-line camelcase
      special_request: itemState.special_request,
      cartItemId: itemState.cartItemId,
      selPromotionalOption: itemState.promotionalOption,
      hasModifierGroups: allModifierGroupsIsInVisible,
      itemLimitReached: itemQuantityLimitCheck,
      orderLimitReached: orderLimitReachedCheck,
      isSelectedChildModifierGroupValid: isSelectedChildModifierGroupValid(
        itemState,
        derivedModifierGroupState
      ),
    },
    itemSessionId,
  };
};

export const PizzaItemExternalIdLeft: string = 'left';
export const PizzaItemExternalIdFull: string = 'full';
export const PizzaItemExternalIdRight: string = 'right';
export const domainSpecificFilterForHidingModifier = (modifierItem: KioskMenuItem) => {
  return modifierItem.itemExternalId === PizzaItemExternalIdFull; // Coverage modifier with full option
};

export const shouldShowModifierOnCheckout = (
  modifier: ModifierService,
  appearance: AppearanceConfig
) => {
  const modifierState = get(modifier);

  if (modifierState.display.isInvisible) {
    return false;
  }

  // Domain specific filter
  if (domainSpecificFilterForHidingModifier(modifier.item)) return false;

  // always show selected modifiers that are not default
  if (modifierState.display.isSelectedNonDefaultModifier) {
    return true;
  }

  // show selected default modifiers when that setting is on
  if (
    (appearance.styleOptions?.orderReviewShowDefaultModifiers &&
      modifierState.display.isSelectedDefaultModifier) ||
    (modifierState.display.isSelectedDefaultModifier && modifierState.display.quantity > 1)
  ) {
    return true;
  }

  // show modifiers with attached modifier codes
  if (
    (modifierState.display.isSelectedNonDefaultModifier ||
      modifierState.display.isSelectedDefaultModifier) &&
    modifierState.display?.selectedModifierCode?.name
  ) {
    return true;
  }

  // always show unselected default modifiers
  if (modifierState.display.isUnselectedDefaultModifier) {
    return true;
  }

  return false;
};

export const scheduleCheck = (categories: KioskCategory[], currentDate: Date) => {
  const currentTime = currentDate.getTime();
  const currentDay = currentDate.getDay();
  return categories.filter((category) => {
    if (category.subCategories?.length > 0) {
      category.subCategories = scheduleCheck(category.subCategories, currentDate);
    }

    category.displayableItems = checkItemSchedule(
      category.displayableItems,
      currentDay,
      currentTime
    );

    if (!category.timeSchedule?.isScheduled) return category;

    return getAvailableScheduledTime(category.timeSchedule.availableTimes, currentDay, currentTime);
  });
};

export const checkItemSchedule = (
  displayableItems: KioskCategoryDisplayableItem[],
  currentDay: number,
  currentTime: number
) => {
  const scheduledItems = displayableItems?.filter((item) => {
    if (item.type === 'item') {
      if (!item.item.timeSchedule.isScheduled) return item;

      return getAvailableScheduledTime(
        item.item.timeSchedule.availableTimes,
        currentDay,
        currentTime
      );
    } else if (item.type === 'combo') {
      if (!item.combo.timeSchedule.isScheduled) return item;

      return getAvailableScheduledTime(
        item.combo.timeSchedule.availableTimes,
        currentDay,
        currentTime
      );
    }
  });
  return scheduledItems;
};

export const getAvailableScheduledTime = (
  availableTimes: KioskAvailableTimes[] | undefined,
  currentDay: number,
  currentTime: number
) => {
  if (!availableTimes) return true;

  return (
    availableTimes
      .filter((time) => time.startDayOfWeek >= currentDay && time.endDayOfWeek <= currentDay)
      .map((timeOftheDay) => {
        const startTime = convertTime(timeOftheDay.startTime);
        const endTime = convertTime(timeOftheDay.endTime);

        if (startTime && endTime) {
          return currentTime >= startTime && currentTime <= endTime;
          // we only hit this if start time is set and end time is unable to be parsed
        } else if (startTime) {
          return currentTime >= startTime;
          // we only hit this if end time is set and start time is unable to be parsed
        } else if (endTime) {
          return currentTime <= endTime;
        } else {
          return true;
        }
      })
      .filter((time) => time).length > 0
  );
};

export const convertTime = (ts: string) => {
  if (!ts) {
    return undefined;
  }

  const targetTime = new Date();
  const [hours, minutes] = ts.split(':');
  targetTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
  return targetTime.getTime();
};

export const isSelectedQtyWithinModGroupMaxQty = (
  // eslint-disable-next-line camelcase
  modifier_group_service: ModGroupService,
  selectedQty: number
) => {
  // eslint-disable-next-line camelcase
  if (modifier_group_service.group.selectMax === 1) return false;
  // eslint-disable-next-line camelcase
  if ([0, null, undefined].some((x) => x === modifier_group_service.group.selectMax)) return true;
  if (
    // eslint-disable-next-line camelcase
    modifier_group_service.group.selectMax > 0 &&
    // eslint-disable-next-line camelcase
    modifier_group_service.group.selectMax >= selectedQty
  )
    return true;

  return false;
};

export const getPriceDeltaFromSelectedModifier = (
  modState: ModifierServiceDerivedState,
  modifierGroup: KioskModifierGroup | undefined,
  modGroupContext: ModGroupServiceContext
) => {
  let priceDelta = 0;
  const shouldUsePriceDelta =
    modGroupContext.display.quantity === 1 && modifierGroup?.selectMax === 1;
  if (shouldUsePriceDelta) {
    const selectedMod = modGroupContext.display.modifiers.find(
      (mod) => get(mod).display.quantity > 0
    )!;
    const selectedModPrice = get(selectedMod).startingPrice;
    priceDelta = modState.startingPrice - selectedModPrice;
  }

  return { priceDelta, shouldUsePriceDelta };
};

const isValidPizzaAttribute = (group) => {
  return (
    group.domainSpecificAttributes &&
    isValidOneOf(group.domainSpecificAttributes.attributes) &&
    group.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
  );
};
const isModifierPizzaCrust = (group) => {
  if (isValidPizzaAttribute(group)) {
    return (
      group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust
    );
  }
  return false;
};
const isModifierPizzaTopping = (group) => {
  if (isValidPizzaAttribute(group)) {
    return (
      group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Topping
    );
  }
  return false;
};
const isModifierPizzaSauce = (group) => {
  if (isValidPizzaAttribute(group)) {
    return (
      group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce
    );
  }
  return false;
};
const isModifierPizzaCoverage = (group) => {
  if (isValidPizzaAttribute(group)) {
    return (
      group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Coverage
    );
  }
  return false;
};
export const areAllModifierGroupsSauceAndTopping = (modifierGroups) => {
  return (
    modifierGroups.length > 0 &&
    (modifierGroups.every((modGroup) => isModifierPizzaTopping(modGroup.group)) ||
      modifierGroups.every((modGroup) => isModifierPizzaSauce(modGroup.group)))
  );
};
export const hasOneRequiredVisibleModifier = (group) => {
  return (
    group.modifiers.filter((modifier) => !modifier.isInvisible).length === 1 && group.selectMin > 0
  );
};

export const hasOneFlattenModifier = (group) => {
  const visibleModifiers = group.modifiers.filter((modifier) => !modifier.isInvisible);
  return (
    visibleModifiers.length === 1 && // modifier should be visible
    group.selectMin === 0 && // modifier should be optional
    visibleModifiers[0].price === 0 && // modifier should have no price
    visibleModifiers[0].menuItem.modifierGroups.length > 0 &&
    visibleModifiers[0].menuItem.modifierGroups.some(
      ({ name }) => name === group.name // modifier child should have different name
    )
  );
};

const cloneModifierGroupsState = (original: ModifierGroupsState): ModifierGroupsState => {
  return new Map(
    [...original.entries()].map(([key, value]) => {
      return [key, cloneModifierGroupState(value)];
    })
  );
};

const cloneModifierGroupState = (original: ModifierGroupState): ModifierGroupState => {
  // Create a new map to hold the cloned modifier states
  const clonedModifiers = new Map<string, ModifierState>();

  // Iterate over each modifier in the original map and clone each ModifierState
  original.modifiers.forEach((modifierState, key) => {
    // Clone the ModifierState object
    const clonedModifierState: ModifierState = {
      ...modifierState,
      // eslint-disable-next-line camelcase
      mod_groups: cloneModifierGroupsState(modifierState.mod_groups), // Shallow copy of mod_groups (ensure deep clone if needed)
      selectedModifierCode: modifierState.selectedModifierCode
        ? { ...modifierState.selectedModifierCode }
        : null,
    };

    // Add the cloned ModifierState to the new map
    clonedModifiers.set(key, clonedModifierState);
  });

  // Return the new ModifierGroupState with the cloned modifiers
  return {
    modifiers: clonedModifiers,
  };
};

export const cloneItemState = (state: ItemServiceInternalState): ItemServiceInternalState => {
  return {
    ...state,
    // eslint-disable-next-line camelcase
    mod_group_states: cloneModifierGroupsState(state.mod_group_states),
  };
};

export const isPizzaItem = (item: KioskMenuItem | undefined) => {
  // If display mode is pizza, has one modifier group which crust
  return (
    item &&
    item.flow === MenuItemDisplayFlow.Pizza &&
    item.modifierGroups.length === 1 &&
    isModifierPizzaCrust(item.modifierGroups[0])
  );
};
